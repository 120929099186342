var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"form-wrapper"},[_c('van-form',{ref:"form"},[_c('van-divider',{style:({
        color: '#1989fa',
        borderColor: '#1989fa',
        padding: '0rem 0.3rem',
      }),attrs:{"content-position":"left"}},[_vm._v("基本信息")]),_c('van-field',{attrs:{"readonly":"","disabled":"","name":"applyName","label":"用户名","placeholder":"请输入姓名","required":""},model:{value:(_vm.form.applyName),callback:function ($$v) {_vm.$set(_vm.form, "applyName", $$v)},expression:"form.applyName"}}),_c('van-field-select',{attrs:{"options":_vm.idCardTypes,"fieldProps":{
        label: '证件类型',
        required: true,
        readonly: true,
        disabled: true,
        placeholder: '请选择证件类型',
      }},model:{value:(_vm.form.idCardType),callback:function ($$v) {_vm.$set(_vm.form, "idCardType", $$v)},expression:"form.idCardType"}}),_c('van-field',{attrs:{"name":"phone","label":"手机号","placeholder":"请输入手机号","required":"","maxlength":"11"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('van-field',{attrs:{"readonly":"","disabled":"","name":"idCardNum","label":"证件号码","placeholder":"请输入证件号码","required":""},model:{value:(_vm.form.idCardNum),callback:function ($$v) {_vm.$set(_vm.form, "idCardNum", $$v)},expression:"form.idCardNum"}}),_c('van-field-select',{attrs:{"options":_vm.genderTypes,"fieldProps":{
        label: '性别',
        placeholder: '请选择性别',
        required: true,
      }},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}}),_c('van-field-month',{attrs:{"name":"birthday","placeholder":"出生年月","pickerProps":{
        type: 'year-month',
        formatter: _vm.formatter,
      },"fieldProps":{
        label: '出生年月',
        placeholder: '请选择',
        required: true,
      }},model:{value:(_vm.form.birthday),callback:function ($$v) {_vm.$set(_vm.form, "birthday", $$v)},expression:"form.birthday"}}),_c('van-field-area',{attrs:{"name":"domicile","fieldProps":{
        required: true,
        label: '户籍所在地',
        placeholder: '请选择户籍所在地',
      },"defaultField":"","defalutProCode":"41"},model:{value:(_vm.form.domicile),callback:function ($$v) {_vm.$set(_vm.form, "domicile", $$v)},expression:"form.domicile"}}),_c('van-field-select',{attrs:{"options":_vm.politics,"fieldProps":{
        label: '政治面貌',
        required: true,
        placeholder: '请选择政治面貌',
      }},model:{value:(_vm.form.politicCountenance),callback:function ($$v) {_vm.$set(_vm.form, "politicCountenance", $$v)},expression:"form.politicCountenance"}}),_c('div',[_c('van-divider',{style:({
          color: '#1989fa',
          borderColor: '#1989fa',
          padding: '0rem 0.3rem',
        }),attrs:{"content-position":"left"}},[_vm._v("最高学历信息")]),_c('van-field',{attrs:{"name":"college","label":"毕业院校","placeholder":"请输入毕业院校","maxlength":"100","required":""},model:{value:(_vm.form.college),callback:function ($$v) {_vm.$set(_vm.form, "college", $$v)},expression:"form.college"}}),_c('van-field',{attrs:{"name":"major","label":"所学专业","placeholder":"请输入所学专业","maxlength":"100","required":""},model:{value:(_vm.form.major),callback:function ($$v) {_vm.$set(_vm.form, "major", $$v)},expression:"form.major"}}),_c('van-field-day',{attrs:{"name":"graduateDate","placeholder":"毕业时间","pickerProps":{
          type: 'date',
        },"fieldProps":{
          label: '毕业时间',
          placeholder: '请选择',
          required: true,
        }},model:{value:(_vm.form.graduateDate),callback:function ($$v) {_vm.$set(_vm.form, "graduateDate", $$v)},expression:"form.graduateDate"}}),_c('van-field-select',{attrs:{"options":_vm.educationTypes,"fieldProps":{
          label: '学历形式',
          placeholder: '请选择学历形式',
          required: true,
        }},model:{value:(_vm.form.educationType),callback:function ($$v) {_vm.$set(_vm.form, "educationType", $$v)},expression:"form.educationType"}}),_c('van-field-select',{attrs:{"options":_vm.overSeaTypes,"fieldProps":{
          label: '海外留学经历',
          placeholder: '请选择海外留学经历',
          required: true,
        }},model:{value:(_vm.form.overSea),callback:function ($$v) {_vm.$set(_vm.form, "overSea", $$v)},expression:"form.overSea"}})],1),_c('div',[_c('van-divider',{style:({
          color: '#1989fa',
          borderColor: '#1989fa',
          padding: '0rem 0.3rem',
        }),attrs:{"content-position":"left"}},[_vm._v("挂职信息")]),_c('van-field',{attrs:{"name":"cmpy","label":"挂职单位","placeholder":"请输入挂职单位","maxlength":"100","required":""},model:{value:(_vm.form.cmpy),callback:function ($$v) {_vm.$set(_vm.form, "cmpy", $$v)},expression:"form.cmpy"}}),_c('van-field',{attrs:{"name":"job","label":"担任职位","placeholder":"请输入担任职位","maxlength":"100","required":""},model:{value:(_vm.form.job),callback:function ($$v) {_vm.$set(_vm.form, "job", $$v)},expression:"form.job"}}),_c('van-field-month',{attrs:{"name":"birthday","placeholder":"挂职开始时间","pickerProps":{
          type: 'year-month',
          formatter: _vm.formatter,
        },"fieldProps":{
          label: '挂职开始时间',
          placeholder: '请选择',
          required: true,
        }},model:{value:(_vm.form.jobTimeBegin),callback:function ($$v) {_vm.$set(_vm.form, "jobTimeBegin", $$v)},expression:"form.jobTimeBegin"}}),_c('van-field-month',{attrs:{"name":"birthday","placeholder":"挂职结束时间","pickerProps":{
          type: 'year-month',
          formatter: _vm.formatter,
        },"fieldProps":{
          label: '挂职结束时间',
          placeholder: '请选择',
          required: true,
        }},model:{value:(_vm.form.jobTimeEnd),callback:function ($$v) {_vm.$set(_vm.form, "jobTimeEnd", $$v)},expression:"form.jobTimeEnd"}})],1),_c('van-divider',{style:({
        color: '#1989fa',
        borderColor: '#1989fa',
        padding: '0rem 0.3rem',
      }),attrs:{"content-position":"left"}},[_vm._v("申请人金融账户")]),_c('div',{staticClass:"notices"},[_c('p',{staticClass:"notice-item"},[_vm._v(" * 1、开户银行具体到支行和行号，若不清楚请拨打银行客服热线进行咨询，以下内容请务必准确无误，否则将影响补贴到账进度或产生退件 ")])]),_c('van-field',{attrs:{"name":"bankId","label":"开户银行","placeholder":"请输入开户银行","required":"","maxlength":"100"},model:{value:(_vm.form.bankId),callback:function ($$v) {_vm.$set(_vm.form, "bankId", $$v)},expression:"form.bankId"}}),_c('van-field',{attrs:{"name":"bankAccount","label":"金融账号","placeholder":"请输入金融账号","required":"","maxlength":"20"},model:{value:(_vm.form.bankAccount),callback:function ($$v) {_vm.$set(_vm.form, "bankAccount", $$v)},expression:"form.bankAccount"}}),_c('van-field',{attrs:{"name":"bankBranch","label":"支行名称","placeholder":"请输入支行名称","required":"","maxlength":"20"},model:{value:(_vm.form.bankBranch),callback:function ($$v) {_vm.$set(_vm.form, "bankBranch", $$v)},expression:"form.bankBranch"}}),_c('van-field',{attrs:{"name":"bankCode","label":"行号","placeholder":"请输入行号","required":"","maxlength":"20"},model:{value:(_vm.form.bankCode),callback:function ($$v) {_vm.$set(_vm.form, "bankCode", $$v)},expression:"form.bankCode"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }