<template>
  <!--
  *@des: 博士服务团生活补贴
  -->
  <section class="form-wrapper">
    <van-form ref="form">
      <van-divider
        content-position="left"
        :style="{
          color: '#1989fa',
          borderColor: '#1989fa',
          padding: '0rem 0.3rem',
        }"
        >基本信息</van-divider
      >
      <van-field
        readonly
        disabled
        v-model="form.applyName"
        name="applyName"
        label="用户名"
        placeholder="请输入姓名"
        required
      />
      <van-field-select
        v-model="form.idCardType"
        :options="idCardTypes"
        :fieldProps="{
          label: '证件类型',
          required: true,
          readonly: true,
          disabled: true,
          placeholder: '请选择证件类型',
        }"
      ></van-field-select>

      <van-field
        v-model="form.phone"
        name="phone"
        label="手机号"
        placeholder="请输入手机号"
        required
        maxlength="11"
      />
      <van-field
        readonly
        disabled
        v-model="form.idCardNum"
        name="idCardNum"
        label="证件号码"
        placeholder="请输入证件号码"
        required
      />

      <van-field-select
        v-model="form.gender"
        :options="genderTypes"
        :fieldProps="{
          label: '性别',
          placeholder: '请选择性别',
          required: true,
        }"
      ></van-field-select>

      <van-field-month
        v-model="form.birthday"
        name="birthday"
        placeholder="出生年月"
        :pickerProps="{
          type: 'year-month',
          formatter: formatter,
        }"
        :fieldProps="{
          label: '出生年月',
          placeholder: '请选择',
          required: true,
        }"
      />
      <van-field-area
        v-model="form.domicile"
        name="domicile"
        :fieldProps="{
          required: true,
          label: '户籍所在地',
          placeholder: '请选择户籍所在地',
        }"
        defaultField=""
        defalutProCode="41"
      />
      <van-field-select
        v-model="form.politicCountenance"
        :options="politics"
        :fieldProps="{
          label: '政治面貌',
          required: true,
          placeholder: '请选择政治面貌',
        }"
      ></van-field-select>
      <div>
        <van-divider
          content-position="left"
          :style="{
            color: '#1989fa',
            borderColor: '#1989fa',
            padding: '0rem 0.3rem',
          }"
          >最高学历信息</van-divider
        >

        <van-field
          v-model="form.college"
          name="college"
          label="毕业院校"
          placeholder="请输入毕业院校"
          maxlength="100"
          required
        />
        <van-field
          v-model="form.major"
          name="major"
          label="所学专业"
          placeholder="请输入所学专业"
          maxlength="100"
          required
        />
        <van-field-day
          v-model="form.graduateDate"
          name="graduateDate"
          placeholder="毕业时间"
          :pickerProps="{
            type: 'date',
          }"
          :fieldProps="{
            label: '毕业时间',
            placeholder: '请选择',
            required: true,
          }"
        />
        <van-field-select
          v-model="form.educationType"
          :options="educationTypes"
          :fieldProps="{
            label: '学历形式',
            placeholder: '请选择学历形式',
            required: true,
          }"
        ></van-field-select>
        <van-field-select
          v-model="form.overSea"
          :options="overSeaTypes"
          :fieldProps="{
            label: '海外留学经历',
            placeholder: '请选择海外留学经历',
            required: true,
          }"
        ></van-field-select>
      </div>
      <div>
        <van-divider
          content-position="left"
          :style="{
            color: '#1989fa',
            borderColor: '#1989fa',
            padding: '0rem 0.3rem',
          }"
          >挂职信息</van-divider
        >
        <van-field
          v-model="form.cmpy"
          name="cmpy"
          label="挂职单位"
          placeholder="请输入挂职单位"
          maxlength="100"
          required
        />
        <van-field
          v-model="form.job"
          name="job"
          label="担任职位"
          placeholder="请输入担任职位"
          maxlength="100"
          required
        />

        <van-field-month
          v-model="form.jobTimeBegin"
          name="birthday"
          placeholder="挂职开始时间"
          :pickerProps="{
            type: 'year-month',
            formatter: formatter,
          }"
          :fieldProps="{
            label: '挂职开始时间',
            placeholder: '请选择',
            required: true,
          }"
        />
        <van-field-month
          v-model="form.jobTimeEnd"
          name="birthday"
          placeholder="挂职结束时间"
          :pickerProps="{
            type: 'year-month',
            formatter: formatter,
          }"
          :fieldProps="{
            label: '挂职结束时间',
            placeholder: '请选择',
            required: true,
          }"
        />
      </div>

      <van-divider
        content-position="left"
        :style="{
          color: '#1989fa',
          borderColor: '#1989fa',
          padding: '0rem 0.3rem',
        }"
        >申请人金融账户</van-divider
      >
      <div class="notices">
        <!-- <p class="notice-item">填写说明</p> -->
        <p class="notice-item">
          *
          1、开户银行具体到支行和行号，若不清楚请拨打银行客服热线进行咨询，以下内容请务必准确无误，否则将影响补贴到账进度或产生退件
        </p>
      </div>
      <!--  -->
      <van-field
        v-model="form.bankId"
        name="bankId"
        label="开户银行"
        placeholder="请输入开户银行"
        required
        maxlength="100"
      />
      <van-field
        v-model="form.bankAccount"
        name="bankAccount"
        label="金融账号"
        placeholder="请输入金融账号"
        required
        maxlength="20"
      />

      <van-field
        v-model="form.bankBranch"
        name="bankBranch"
        label="支行名称"
        placeholder="请输入支行名称"
        required
        maxlength="20"
      />
      <van-field
        v-model="form.bankCode"
        name="bankCode"
        label="行号"
        placeholder="请输入行号"
        required
        maxlength="20"
      />
    </van-form>
  </section>
</template>

<script>
import formMixin from "../mixin";
import Schema from "async-validator";
import rules from "./rules";
export default {
  name: "young-house-subsidy-zz2017",
  mixins: [formMixin],
  data() {
    return {
      form: {
        applyName: "", //姓名
        idCardType: "居民身份证", //证件类型
        phone: "",
        idCardNum: "", //证件号码
        bankId: "", //社会保障卡开户银行
        bankAccount: "", //社会保障卡金融账号
        domicile: [], //户籍地址
        domicileTime: "",
        bankBranch: "", //请输入支行名称

        //学历信息
        college: "",
        major: "",
        education: "",
        educationType: "",
        graduateDate: "",
      },
      formRules: rules,
      formValidator: null,

      idCardTypes: [
        {
          value: "居民身份证",
          label: "居民身份证",
        },
      ],
      politics: [
        { value: "中共党员(含预备党员)", label: "中共党员(含预备党员)" },
        { value: "民主党派", label: "民主党派" },
        { value: "无党派人士", label: "无党派人士" },
        { value: "团员", label: "团员" },
        { value: "群众", label: "群众" },
      ],
      overSeaTypes: [
        {
          value: "是",
          label: "是",
        },
        {
          value: "否",
          label: "否",
        },
      ],
      educations: [
        {
          label: "本科",
          value: "本科",
        },
        {
          label: "硕士研究生",
          value: "硕士研究生",
        },
        {
          label: "博士研究生",
          value: "博士研究生",
        },
      ],

      educationTypes: [
        {
          value: "全日制",
          label: "全日制",
        },
        {
          value: "非全日制",
          label: "非全日制",
        },
      ],
      degrees: [
        {
          value: "学士",
          label: "学士",
        },
        {
          value: "硕士",
          label: "硕士",
        },
        {
          label: "博士",
          value: "博士",
        },
      ],

      genderTypes: [
        {
          value: "男",
          label: "男",
        },
        {
          value: "女",
          label: "女",
        },
      ],
    };
  },
  computed: {},
  created() {
    this.initFamilyFormValidator();
  },
  methods: {
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    /*
     *@des: 初始化校验
     */
    initFamilyFormValidator() {
      this.formValidator = new Schema(this.formRules);
    },

    /*
     *@des: 校验表单
     */
    validate() {
      return new Promise((resolve, reject) => {
        this.formValidator
          .validate(this.form, (errors) => {
            if (errors && errors.length) {
              this.$toast(errors[0].message);
            }
          })
          .then(async () => {
            let beginYear = this.form.jobTimeBegin.split("-")[0];
            let beginMonth = this.form.jobTimeBegin.split("-")[1];
            let endYear = this.form.jobTimeEnd.split("-")[0];
            let endMonth = this.form.jobTimeEnd.split("-")[1];
            if (beginYear * 12 + beginMonth > endYear * 12 + endMonth) {
              this.$toast("挂职结束时间不能早于挂职开始时间");
              return;
            }
            resolve({
              ...this.form,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-wrapper {
  margin-top: 0.2rem;
  background: #fff;
  .title-gutter {
    width: fit-content;
    margin-left: 0.3rem;
    padding: 0.3rem 0.1rem 0rem 0rem;
    font-size: 0.26rem;
    line-height: 0.26rem;
    border-bottom: 2px solid #3c37d2;
  }
  .fam-btns {
    padding: 0.3rem;
    margin-bottom: 0.3rem;
    border-bottom: 1px solid #eee;
    .btn {
      width: 1.5rem;
      margin-right: 0.15rem;
    }
  }
}
.tip {
  margin: 0.2rem;
}
</style>
